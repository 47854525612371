import React from 'react';
import {Container, Button, Row, Col, Modal, Form} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import API from '../utils/API';

import styles from './KapChat.module.scss';

const KAPCHAT_EMBED_URL = 'https://nightdev.com/hosted/obschat';
const DEFAULT_OPTIONS = {
  theme: 'null',
  channel: '',
  fade: false,
  bot_activity: false,
  prevent_clipping: false,
};
const THEMES = {
  dark: 'Dark',
  light: 'Light',
  bttv_light: 'BetterTTV Light',
  bttv_dark: 'BetterTTV Dark',
  s0n0s_1080: `S0N0S' 1080P Theme`,
  s0n0s_1440: `S0N0S' 1440P Theme`,
};

function generate(options) {
  if (options.theme === 'null') {
    options.theme = undefined;
  }
  return `${KAPCHAT_EMBED_URL}?${new URLSearchParams({...DEFAULT_OPTIONS, ...options}).toString()}`;
}

export default class KapChat extends React.Component {
  constructor() {
    super();
    this.state = {
      demoChannel: null,
      generateModalOpen: false,
      generatedModalOpen: false,
      previewModalOpen: false,
      form: {...DEFAULT_OPTIONS},
    };
  }

  async componentDidMount() {
    const {
      body: {channel},
    } = await API.get('kapchat/stream');
    this.setState({demoChannel: channel});
  }

  handlePreviewModalOpen = () => {
    this.setState({previewModalOpen: true, generateModalOpen: false});
  };

  handlePreviewModalClose = () => {
    this.setState({previewModalOpen: false, generateModalOpen: true});
  };

  handleGenerateModalOpen = () => {
    this.setState({generateModalOpen: true, generatedModalOpen: false});
  };

  handleGenerateModalClose = () => {
    this.setState({generateModalOpen: false});
  };

  handleGeneratedModalOpen = () => {
    this.setState({generatedModalOpen: true, generateModalOpen: false});
  };

  handleGeneratedModalClose = () => {
    this.setState({generatedModalOpen: false});
  };

  handleFormChange = ({currentTarget: {name, type, value, checked}}) => {
    const {form} = this.state;
    if (type === 'checkbox') {
      value = checked;
    }
    if (name === 'fade' && value === true) {
      value = 30;
    }
    form[name] = value;
    this.setState({form});
  };

  handleClickGeneratedURL = ({currentTarget}) => {
    currentTarget.setSelectionRange(0, currentTarget.value.length);
  };

  renderPreviewModal() {
    const {demoChannel, previewModalOpen, form} = this.state;
    return (
      <Modal show={previewModalOpen} onHide={this.handlePreviewModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            className={styles.demoChat}
            src={generate({...form, channel: demoChannel || form.channel})}
            title="Chat Demo"
          />
        </Modal.Body>
      </Modal>
    );
  }

  renderGenerateModal() {
    const {generateModalOpen, form} = this.state;
    return (
      <Modal show={generateModalOpen} onHide={this.handleGenerateModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>KapChat Setup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} className="pb-3">
            <Form.Label column sm="4">
              Channel
            </Form.Label>
            <Col sm="8">
              <Form.Control type="text" name="channel" placeholder="channelname" onChange={this.handleFormChange} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="pb-3">
            <Form.Label column sm="4">
              Fade
            </Form.Label>
            <Col sm="8">
              <div className="d-flex">
                <Form.Check
                  type="checkbox"
                  name="fade"
                  onChange={this.handleFormChange}
                  checked={form.fade !== false}
                  className="align-self-center"
                />
                {form.fade !== false ? (
                  <Form.Control
                    className="ms-2"
                    disabled={!form.fade}
                    value={form.fade ? form.fade : 30}
                    type="number"
                    name="fade"
                    placeholder="30"
                    onChange={this.handleFormChange}
                  />
                ) : null}
              </div>
              <Form.Text className="text-muted">
                You can fade out old chat lines after a set number of seconds to keep stale content from remaining on
                screen.
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="pb-3">
            <Form.Label column sm="4">
              Show Bots
            </Form.Label>
            <Col sm="8">
              <Form.Check
                type="checkbox"
                name="bot_activity"
                onChange={this.handleFormChange}
                checked={form.bot_activity === true}
              />
              <Form.Text className="text-muted">
                We hide bot messages by default, but you can turn them back on.
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="pb-3">
            <Form.Label column sm="4">
              Prevent Clipping
            </Form.Label>
            <Col sm="8">
              <Form.Check
                type="checkbox"
                name="prevent_clipping"
                onChange={this.handleFormChange}
                checked={form.prevent_clipping === true}
              />
              <Form.Text className="text-muted">
                You can prevent the clipping of chat lines, but it is disabled by default for performance reasons. If
                you have a large channel, you might not want to enable this.
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="pb-3">
            <Form.Label column sm="4">
              Chat Theme
            </Form.Label>
            <Col sm="8">
              <Form.Control as="select" name="theme" onChange={this.handleFormChange} value={form.theme}>
                <option value="null">None</option>
                {Object.keys(THEMES).map(theme => (
                  <option key={theme} value={theme}>
                    {THEMES[theme]}
                  </option>
                ))}
              </Form.Control>
              <Form.Text className="text-muted">
                You should choose a style that fits the game you're going to be playing. If you're an advanced user
                (meaning you know CSS), you can also choose to use "None" and style chat yourself.
              </Form.Text>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-info" onClick={this.handlePreviewModalOpen}>
            Preview
          </Button>
          <Button variant="primary" onClick={this.handleGeneratedModalOpen}>
            Generate
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderGeneratedModal() {
    const {generatedModalOpen, form} = this.state;
    return (
      <Modal show={generatedModalOpen} onHide={this.handleGeneratedModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Generated URL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            In your streaming client, add a new Browser or Webpage Source. Then, configure it with the following URL:
          </p>
          <p>
            <Form.Control type="text" value={generate(form)} onClick={this.handleClickGeneratedURL} />
          </p>
          <p>
            You will also want to play with the width and height of the source. If you aren't sure what to use, try
            starting with a height of <code>600</code> and a width of <code>300</code> and change each value until you
            find a size you like.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleGenerateModalOpen}>
            Back
          </Button>
          <Button variant="primary" onClick={this.handleGeneratedModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const {demoChannel} = this.state;
    return (
      <>
        {this.renderPreviewModal()}
        {this.renderGenerateModal()}
        {this.renderGeneratedModal()}
        <Helmet>
          <title>KapChat</title>
          <meta name="description" content="Embed Twitch chat directly into your stream with OBS and XSplit" />
        </Helmet>
        <div className="w-100">
          <Container className="pt-5 pb-0 mb-5 w-100 bg-light">
            <Row className="align-items-center">
              <Col sm="12" md="6" className="text-center pb-5">
                {demoChannel != null ? (
                  <iframe
                    className={styles.demoChat}
                    src={generate({channel: demoChannel, theme: 'bttv_dark'})}
                    title="Chat Demo"
                  />
                ) : null}
              </Col>
              <Col sm="12" md="6" className="pb-5">
                <h1>KapChat</h1>
                <p>
                  Embed Twitch chat directly into your stream with OBS &amp; XSplit (BetterTTV and FrankerFaceZ emotes
                  too)
                </p>
                <br />
                <p>
                  <Button variant="primary" size="lg" onClick={this.handleGenerateModalOpen}>
                    Get It
                  </Button>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
