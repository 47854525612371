import React from 'react';
import {Container, Button, Row, Col, Card, ListGroup, Badge, Modal, Form, Alert} from 'react-bootstrap';
import API from '../utils/API';
import superagent from 'superagent';
import {Helmet} from 'react-helmet';

const GOOGLE_CAST_SCRIPT_ID = 'google_cast_sender';
const SENDER_SOURCE_URL = 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';
const RECEIVER_APPLICATION_ID = 'DAC1CD8C';
const POINTS_OF_PRESENCE = [
  {
    name: 'US West',
    endpoint: 'https://hls-us-west.nightdev.com',
  },
  {
    name: 'US East',
    endpoint: 'https://hls-us-east.nightdev.com',
  },
  {
    name: 'EU',
    endpoint: 'https://hls-eu.nightdev.com',
  },
];

export default class TwitchCast extends React.Component {
  constructor() {
    super();
    this.state = {
      chromecastPlayer: null,
      error: null,
      pointsOfPresence: POINTS_OF_PRESENCE.map(({name, endpoint}) => ({name, endpoint, latency: -1, load: -1})),
      selectedPointOfPresence: null,
      modalOpen: false,
      channel: '',
      casting: false,
      submitting: false,
      playlist: null,
      selectedPlaylistItem: null,
      selectedLayout: 'video',
    };
  }

  componentDidMount() {
    if (document.getElementById(GOOGLE_CAST_SCRIPT_ID) == null) {
      const script = document.createElement('script');
      script.id = GOOGLE_CAST_SCRIPT_ID;
      script.src = SENDER_SOURCE_URL;
      script.async = true;
      document.head.appendChild(script);
    }

    window.__onGCastApiAvailable = isAvailable => (isAvailable ? this.initializeChromecastAPI() : null);
    try {
      if (window.chrome.cast.isAvailable) {
        this.initializeChromecastAPI();
      }
    } catch (_) {}

    const options = new URLSearchParams(this.props.location.search);
    this.setState({channel: options.get('channel') || this.state.channel, selectedLayout: options.get('layout') || this.state.selectedLayout});

    this.updatePointOfPresenceLatencies();
  }

  componentWillUnmount() {
    delete window.__onGCastApiAvailable;
  }

  getChromecastCurrentSession() {
    return window.cast.framework.CastContext.getInstance().getCurrentSession();
  }

  initializeChromecastAPI = () => {
    if (window.cast == null || window.chrome.cast == null) {
      return;
    }

    window.cast.framework.CastContext.getInstance().setOptions({
      receiverApplicationId: RECEIVER_APPLICATION_ID,
      autoJoinPolicy: window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
    });
    const player = new window.cast.framework.RemotePlayer();

    this.setState({
      chromecastPlayer: player,
    });

    console.log('Cast: API Initialized');
  };

  async updatePointOfPresenceLatencies() {
    const {pointsOfPresence} = this.state;
    pointsOfPresence.forEach(async (pointOfPresence) => {
      const startTime = Date.now();
      try {
        const {
          body: {tx, rx},
        } = await superagent.get(`${pointOfPresence.endpoint}/stats`).set('accept', 'json');
        pointOfPresence.latency = Date.now() - startTime;
        pointOfPresence.load = Math.max(Math.round((tx / 500) * 100), Math.round((rx / 500) * 100));
      } catch (_) {}
      this.setState({pointsOfPresence});
      this.autoselectPointOfPresence();
    });
  }

  autoselectPointOfPresence() {
    const {pointsOfPresence} = this.state;
    pointsOfPresence.sort((a, b) => a.latency - b.latency);
    const filteredPointsOfPresence = pointsOfPresence.filter(({latency}) => latency > -1);
    this.setState({selectedPointOfPresence: filteredPointsOfPresence[0]});
  }

  async requestSessionAndPlayMedia(url, options = {}) {
    if (this.getChromecastCurrentSession()) {
      return this.playChromecastMedia(url, options);
    }
    try {
      await window.cast.framework.CastContext.getInstance().requestSession();
      console.log('Cast: Session Requested');
      return this.playChromecastMedia(url, options);
    } catch (error) {
      console.log(`Cast: Request Session Failed with Error Code ${error.code || 0}`);
      this.setState({error: error.description || 'Unknown Error'});
    }
  }

  async playChromecastMedia(url, options = {}) {
    const mediaInfo = new window.chrome.cast.media.MediaInfo(url, 'video/mp4');

    const request = new window.chrome.cast.media.LoadRequest(mediaInfo);
    request.autoplay = true;
    request.currentTime = 0;
    request.customData = options;

    try {
      await this.getChromecastCurrentSession().loadMedia(request);
      console.log('Cast: Load Media Succeeded');
    } catch (error) {
      console.log(`Cast: Load Media Failed with Error Code ${error.code || 0}`);
      this.setState({error: error.description || 'Unknown Error'});
    }
  }

  handleModalClose = () => {
    this.setState({modalOpen: false});
  };

  handelModalOpen = () => {
    if (this.state.channel) {
      this.handleStartCasting();
    }
    this.setState({modalOpen: true});
  };

  handleStopCasting = async () => {
    try {
      await this.getChromecastCurrentSession().endSession(true);
    } catch (_) {}
    this.setState({casting: false});
  };

  handleStartCasting = async () => {
    const {selectedPointOfPresence, selectedLayout, channel} = this.state;
    this.setState({submitting: true, error: null});
    try {
      const {
        body: {token, sig},
      } = await API.get('twitchcast/token', {channel});

      const {
        body: {playlist},
      } = await superagent
        .get(`${selectedPointOfPresence.endpoint}/get/playlist`)
        .query({
          channel,
          token,
          sig,
        })
        .set('accept', 'json');

      this.setState({playlist, selectedPlaylistItem: playlist[0], submitting: false, casting: true});
      this.requestSessionAndPlayMedia(playlist[0].url, {channel, layout: selectedLayout});
    } catch (e) {
      this.setState({submitting: false, error: e.response ? e.response.body.message : 'unknown error'});
      return;
    }
  };

  handleChannelChange = ({currentTarget: {value}}) => {
    this.setState({channel: value});
  };

  handleOptionChange = ({currentTarget: {name, value}}) => {
    let {channel, playlist, selectedLayout, selectedPlaylistItem} = this.state;
    if (name === 'layout') {
      selectedLayout = value;
    }
    if (name === 'quality') {
      selectedPlaylistItem = playlist.find(({name}) => name === value);
    }
    this.requestSessionAndPlayMedia(selectedPlaylistItem.url, {channel, layout: selectedLayout});
    this.setState({selectedLayout, selectedPlaylistItem});
  };

  renderModal() {
    const {error, modalOpen, casting, channel, submitting, playlist, selectedLayout, selectedPlaylistItem} = this.state;
    return (
      <Modal show={modalOpen} onHide={this.handleModalClose} size="sm" centered>
        <Modal.Header closeButton>
          <Modal.Title>Cast Setup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error != null ? <Alert variant="danger">{error}</Alert> : null}
          {!casting ? (
            <Form.Control type="text" placeholder="Channel Name" value={channel} onChange={this.handleChannelChange} />
          ) : (
            <>
              <Form.Group className="pb-3">
                <Form.Label>Quality</Form.Label>
                <Form.Control
                  as="select"
                  name="quality"
                  onChange={this.handleOptionChange}
                  value={selectedPlaylistItem.name}>
                  {playlist.map(({name, bandwidth}) => (
                    <option key={name} value={name}>
                      {name}@{(bandwidth / 1000).toFixed(1)}Mbps
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="pb-3">
                <Form.Label>Layout</Form.Label>
                <Form.Control as="select" name="layout" onChange={this.handleOptionChange} value={selectedLayout}>
                  <option value="video">Video Only</option>
                  <option value="chat-left">Chat on Left</option>
                  <option value="chat-right">Chat on Right</option>
                  <option value="chat-top">Chat on Top</option>
                  <option value="chat-bottom">Chat on Bottom</option>
                </Form.Control>
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {casting ? (
            <Button onClick={this.handleStopCasting} variant="outline-danger">
              Stop Casting
            </Button>
          ) : (
            <Button onClick={this.handleStartCasting} disabled={submitting}>
              Start Casting
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const {pointsOfPresence, chromecastPlayer} = this.state;
    return (
      <>
        <Helmet>
          <title>TwitchCast</title>
          <meta name="description" content="Watch Twitch with chat on your TV using Chromecast." />
        </Helmet>
        <div className="w-100">
          {this.renderModal()}
          <Container className="pt-5 pb-0 mb-5 w-100 bg-light">
            <Row className="align-items-center">
              <Col sm="12" md="6" className="text-center pb-5">
                <img src="/images/twitchcast.png" alt="" className="mw-100" />
              </Col>
              <Col sm="12" md="6" className="pb-5">
                <h1>TwitchCast</h1>
                <p>Watch Twitch with chat on your TV using Chromecast.</p>
                <br />
                <p>
                  <Button
                    disabled={chromecastPlayer == null}
                    variant="primary"
                    size="lg"
                    onClick={this.handelModalOpen}>
                    Start Casting
                  </Button>
                </p>
              </Col>
            </Row>
          </Container>
          <Row>
            <Col sm="12" md="6" lg="4" className="pb-3">
              <Card>
                <Card.Header>Features</Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>Multiple Resolutions (if available)</ListGroup.Item>
                  <ListGroup.Item>Chat</ListGroup.Item>
                  <ListGroup.Item>BetterTTV and FrankerFaceZ Emotes</ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
            <Col sm="12" md="6" lg="4" className="pb-3">
              <Card>
                <Card.Header>Server Status</Card.Header>
                <ListGroup variant="flush">
                  {pointsOfPresence.map(({name, latency, load}) => {
                    let badgeVariant = 'secondary';
                    if (load > 75) {
                      badgeVariant = 'danger';
                    } else if (load > 50) {
                      badgeVariant = 'warning';
                    } else if (load > -1) {
                      badgeVariant = 'success';
                    }
                    return (
                      <ListGroup.Item key={name} className="d-flex justify-content-between">
                        {name}{' '}
                        <div>
                          <Badge bg={badgeVariant} pill>
                            {latency > -1 ? `${latency} ms, ${load}% load` : 'unknown'}
                          </Badge>
                        </div>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
