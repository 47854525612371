import React from 'react';
import {Helmet} from 'react-helmet';
import {Form, Row, Col, Button, Alert} from 'react-bootstrap';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import API from '../utils/API';
import {ExternalURLs} from '../Constants';
import {
  TopicNames,
  ProductNames,
  AlternateContactMethods,
  AlternateProductContactMethods,
  HCAPTCHA_SITEKEY,
} from '../lib/ContactFormConstants';

const FORM_DEFAULTS = {
  topic: '',
  product: '',
  name: '',
  username: '',
  email: '',
  message: '',
  captcha: null,
};

export default class Contact extends React.Component {
  constructor(props) {
    super();
    const {location: {search}} = props;
    const query = Object.fromEntries(new URLSearchParams(search.substr(1)));
    const topic = Object.keys(TopicNames).includes(query.topic) ? query.topic : FORM_DEFAULTS.topic;
    const product = Object.keys(ProductNames).includes(query.product) ? query.product : FORM_DEFAULTS.product;
    this.state = {
      form: {...FORM_DEFAULTS, topic, product},
      showForm: false,
      error: null,
      submitting: false,
      submitted: false,
    };
  }

  hcaptchaRef = React.createRef();

  handleFieldChanged = ({currentTarget: {name, value}}) => {
    const {form} = this.state;
    form[name] = value;
    this.setState({form});
  };

  handleCaptchaChanged = captcha => {
    const {form} = this.state;
    form.captcha = captcha;
    this.setState({form});
  };

  handleSubmit = async () => {
    const {form} = this.state;
    for (const key of Object.keys(form)) {
      if (FORM_DEFAULTS[key] !== form[key]) {
        continue;
      }
      this.setState({error: `"${key}" is not filled out. All form fields are required.`});
      return;
    }
    const cleanedMessage = form.message.replace(/ +/g, ' ').replace(/\n\n+/g, '\n\n');
    if (cleanedMessage.length < 50) {
      this.setState({
        error:
          'The message you are attempting to send does not appear to be detailed enough. Try providing more details and specifics.',
      });
      return;
    }
    this.setState({submitting: true, error: null});
    try {
      await API.post('contact/email', form);
      this.setState({submitting: false, submitted: true, form: {...FORM_DEFAULTS}});
    } catch (e) {
      this.setState({
        submitting: false,
        error: e.response.body.message || 'An Internal Server Error occurred. Please try again later.',
      });
    }
    try {
      this.hcaptchaRef.current.reset();
    } catch (_) {}
  };

  renderAlternativeContactMethod() {
    const {form} = this.state;
    const alternateProductContactMethod = AlternateProductContactMethods.find(
      ({product, topics}) => product === form.product && topics.includes(form.topic)
    );
    if (alternateProductContactMethod == null) {
      return null;
    }

    let message;
    let buttonUrl;
    let buttonText;

    if (alternateProductContactMethod.type === AlternateContactMethods.GITHUB) {
      message = `You can submit a ${TopicNames[form.topic]} over on our GitHub repo.`;
      buttonUrl = alternateProductContactMethod.url;
      buttonText = `Head to the ${ProductNames[form.product]} GitHub repo`;
    } else if (alternateProductContactMethod.type === AlternateContactMethods.FORUM) {
      message = `You can submit a ${TopicNames[form.topic]} over on our forum.`;
      buttonUrl = `${ExternalURLs.FORUMS}/c/${form.product}`;
      buttonText = `Head to the ${ProductNames[form.product]} forum`;
    }

    if (message == null) {
      return null;
    }

    return (
      <div>
        <p>{message}</p>
        <Button href={buttonUrl}>{buttonText}</Button>
      </div>
    );
  }

  render() {
    const {form, error, submitting, submitted} = this.state;
    const alternateProductContactMethod = this.renderAlternativeContactMethod();
    const renderForm = alternateProductContactMethod == null && form.topic.length > 0 && form.product.length > 0;
    return (
      <>
        <Helmet>
          <title>Contact</title>
        </Helmet>
        <div className="w-100">
          <h1>Contact</h1>
          {error != null ? <Alert variant="danger">{error}</Alert> : null}
          {submitted ? (
            <Alert variant="success">
              <h4>Success!</h4>
              Your message has been sent. We'll get back to you as soon as possible.
            </Alert>
          ) : null}
          <Form.Group className="pb-3">
            <Form.Label>What can we help you with?</Form.Label>
            <Form.Control as="select" name="topic" value={form.topic} onChange={this.handleFieldChanged}>
              <option disabled hidden style={{display: 'none'}} value="" />
              {Object.keys(TopicNames).map(key => (
                <option key={key} value={key}>
                  {TopicNames[key]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="pb-3">
            <Form.Label>What product does this relate to?</Form.Label>
            <Form.Control as="select" name="product" value={form.product} onChange={this.handleFieldChanged}>
              <option disabled hidden style={{display: 'none'}} value="" />
              {Object.keys(ProductNames).map(key => (
                <option key={key} value={key}>
                  {ProductNames[key]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {renderForm ? (
            <>
              <Row>
                <Form.Group as={Col} className="pb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="John Doe"
                    name="name"
                    value={form.name}
                    onChange={this.handleFieldChanged}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} className="pb-3">
                  <Form.Label>Account Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="johndoe"
                    name="username"
                    value={form.username}
                    onChange={this.handleFieldChanged}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} className="pb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="john.doe@example.com"
                    onChange={this.handleFieldChanged}
                    value={form.email}
                    name="email"
                    required
                  />
                </Form.Group>
              </Row>

              <Form.Group className="pb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Here you should place your message to be sent. Please provide details and specifics."
                  name="message"
                  value={form.message}
                  onChange={this.handleFieldChanged}
                  rows="5"
                />
              </Form.Group>

              <Form.Group className="pb-3">
                <HCaptcha ref={this.hcaptchaRef} sitekey={HCAPTCHA_SITEKEY} onVerify={this.handleCaptchaChanged} />
              </Form.Group>

              <Button disabled={submitting} variant="primary" type="submit" onClick={this.handleSubmit}>
                Submit
              </Button>
            </>
          ) : (
            alternateProductContactMethod
          )}
        </div>
      </>
    );
  }
}
