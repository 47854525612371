import superagent from 'superagent';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '/api/1/';

function request(method, route, body) {
  let request = superagent[method](`${API_ENDPOINT}${route}`).set('accept', 'json');

  if (body != null) {
    if (method === 'get') {
      request = request.query(body);
    } else {
      request = request.send(body);
    }
  }

  return request;
}

const API = {
  get: (route, query) => request('get', route, query),
  post: (route, body) => request('post', route, body),
  put: (route, body) => request('put', route, body),
  patch: (route, body) => request('patch', route, body),
  delete: (route) => request('delete', route),
};

export default API;
