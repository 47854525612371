export const ExternalURLs = {
  BETTERTTV: 'https://betterttv.com',
  NIGHTBOT: 'https://nightbot.tv',
  PUSSH: 'https://pussh.me',
  DISCORD: 'https://discord.gg/nightdev',
  FORUMS: 'https://community.nightdev.com',
  TWITTER: 'https://twitter.com/nightdev',
};

export const WebRoutes = {
  INDEX: '/',
  CONTACT: ({topic, product} = {}) =>
    `/contact${
      topic != null ? `?topic=${encodeURIComponent(topic || '')}&product=${encodeURIComponent(product || '')}` : ''
    }`,
  TERMS: '/terms',
  PRIVACY: '/privacy',
  KAPCHAT: '/kapchat',
  KAPCHAT_HOSTED: '/hosted/kapchat',
  TWITCHCAST: '/twitchcast',
};
