export const HCAPTCHA_SITEKEY = 'aab323b8-a41a-4293-ae2f-0213a6f7bcce';

export const Topics = {
  SUPPORT: 'support',
  FEATURE_REQUEST: 'feature_request',
  BUG_REPORT: 'bug_report',
  BILLING: 'billing',
  ACCOUNT: 'account',
  BUSINESS_DEVELOPMENT: 'business_development',
  LEGAL: 'legal',
  OTHER: 'other',
};

export const Products = {
  BETTERTTV: 'betterttv',
  NIGHTBOT: 'nightbot',
  KAPCHAT: 'kapchat',
  TWITCHCAST: 'twitchcast',
  OTHER: 'other',
};

export const AlternateContactMethods = {
  GITHUB: 'github',
  FORUM: 'forum',
};

export const TopicNames = {
  [Topics.SUPPORT]: 'Help & Support Request',
  [Topics.FEATURE_REQUEST]: 'Feature Request',
  [Topics.BUG_REPORT]: 'Bug Report',
  [Topics.BILLING]: 'Billing Issue',
  [Topics.ACCOUNT]: 'Account Issue',
  [Topics.BUSINESS_DEVELOPMENT]: 'Business Development',
  [Topics.LEGAL]: 'Legal',
  [Topics.OTHER]: 'Other',
};

export const ProductNames = {
  [Products.BETTERTTV]: 'BetterTTV',
  [Products.NIGHTBOT]: 'Nightbot',
  [Products.KAPCHAT]: 'KapChat',
  [Products.TWITCHCAST]: 'TwitchCast',
  [Products.OTHER]: 'Other',
};

export const AlternateProductContactMethods = [
  {
    type: AlternateContactMethods.GITHUB,
    product: Products.BETTERTTV,
    topics: [Topics.FEATURE_REQUEST, Topics.BUG_REPORT],
    url: 'https://github.com/night/BetterTTV/issues',
  },
  {
    type: AlternateContactMethods.GITHUB,
    product: Products.BETTERTTV,
    topics: [Topics.FEATURE_REQUEST],
    url: 'https://github.com/night/BetterTTV/discussions',
  },
  {
    type: AlternateContactMethods.FORUM,
    product: Products.BETTERTTV,
    topics: [Topics.SUPPORT],
  },
  {
    type: AlternateContactMethods.FORUM,
    product: Products.NIGHTBOT,
    topics: [Topics.SUPPORT, Topics.FEATURE_REQUEST, Topics.BUG_REPORT],
  },
  {
    type: AlternateContactMethods.FORUM,
    product: Products.KAPCHAT,
    topics: [Topics.SUPPORT, Topics.FEATURE_REQUEST, Topics.BUG_REPORT],
  },
  {
    type: AlternateContactMethods.FORUM,
    product: Products.TWITCHCAST,
    topics: [Topics.SUPPORT, Topics.FEATURE_REQUEST, Topics.BUG_REPORT],
  },
];
