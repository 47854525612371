import React from 'react';
import {Route, useLocation, Link, Routes, Navigate} from 'react-router-dom';
import {Navbar, Nav, NavItem, Container, Dropdown} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {WebRoutes, ExternalURLs} from './Constants';
import Index from './components/Index';
import Contact from './components/Contact';
import KapChat from './components/KapChat';
import TwitchCast from './components/TwitchCast';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import {Projects} from './components/ProjectsDropdown';
import classNames from 'classnames';
import {DropdownItem} from './components/Dropdown';
import {faComments, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faDiscord, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {TrackedComponent} from './utils/Tracking';

import styles from './App.module.scss';

export default function App(props) {
  const location = useLocation();
  return (
    <div className={styles.pageContainer}>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <LinkContainer to={WebRoutes.INDEX}>
            <Navbar.Brand>NightDev</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="nightdev-header-nav" />
          <Navbar.Collapse id="nightdev-header-nav">
            <Nav className="me-auto" activeKey={location.pathname}>
              <LinkContainer to={WebRoutes.INDEX}>
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <Dropdown as={NavItem}>
                <Dropdown.Toggle as={Nav.Link}>Projects</Dropdown.Toggle>
                <Dropdown.Menu>{Projects()}</Dropdown.Menu>
              </Dropdown>
              <Dropdown as={NavItem}>
                <Dropdown.Toggle as={Nav.Link}>Support</Dropdown.Toggle>
                <Dropdown.Menu>
                  <DropdownItem href={ExternalURLs.DISCORD} icon={faDiscord}>
                    Discord
                  </DropdownItem>
                  <DropdownItem href={ExternalURLs.FORUMS} icon={faComments}>
                    Community Forums
                  </DropdownItem>
                  <DropdownItem href={ExternalURLs.TWITTER} icon={faTwitter}>
                    Twitter
                  </DropdownItem>
                  <LinkContainer to={WebRoutes.CONTACT()}>
                    <DropdownItem icon={faEnvelope}>Email Us</DropdownItem>
                  </LinkContainer>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className={classNames('pt-3', 'd-flex', styles.pageContent)}>
        <Routes>
          <Route path={WebRoutes.INDEX} element={<TrackedComponent component={Index} />} />
          <Route path={WebRoutes.CONTACT()} element={<TrackedComponent component={Contact} />} />
          <Route path={WebRoutes.KAPCHAT} element={<TrackedComponent component={KapChat} />} />
          <Route path={WebRoutes.TWITCHCAST} element={<TrackedComponent component={TwitchCast} />} />
          <Route path={WebRoutes.TERMS} element={<TrackedComponent component={Terms} />} />
          <Route path={WebRoutes.PRIVACY} element={<TrackedComponent component={Privacy} />} />
          <Route path="*" element={<Navigate to={WebRoutes.INDEX} />} />
        </Routes>
      </Container>
      <Container className="d-flex pt-3 pb-3">
        <footer>Copyright &copy; {new Date().getFullYear()} NightDev, LLC. All Rights Reserved.</footer>
        <footer className="ms-auto">
          <Link to={WebRoutes.TERMS}>Terms of Service</Link> &#8226; <Link to={WebRoutes.PRIVACY}>Privacy Policy</Link>
        </footer>
      </Container>
    </div>
  );
}
