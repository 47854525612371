import React, {useEffect} from 'react';
import ReactGA from 'react-ga';
import {useLocation} from 'react-router-dom';

export const TrackedComponent = ({children, component: Component, ...options}) => {
  const location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    setTimeout(() => {
      ReactGA.set({
        page,
        ...options,
      });
      ReactGA.pageview(page);
    }, 0);
  }, [location.pathname, options]);

  return <Component location={location} />;
};
