import React from 'react';
import {Button, DropdownButton} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {Projects} from './ProjectsDropdown';
import classnames from 'classnames';
import {WebRoutes} from '../Constants';

import styles from './Index.module.scss';

class Index extends React.Component {
  render() {
    return (
      <div className="px-5 py-3 p-md-5 w-100 mx-auto text-center align-self-center">
        <p className={classnames(styles.aboutDescription, 'lead', 'text-center')}>
          NightDev is a company building products around live streaming and chat. We operate one of the largest chat
          bots on Twitch &amp; YouTube as well as a Twitch browser extension servicing millions of users daily.
        </p>
        <br />
        <div>
          <DropdownButton title="Explore Projects" variant="primary" size="lg">
            {Projects()}
          </DropdownButton>
          <LinkContainer to={WebRoutes.CONTACT()}>
            <Button variant="link">Contact Us</Button>
          </LinkContainer>
        </div>
      </div>
    );
  }
}

export default Index;
