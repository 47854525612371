import React from 'react';
import {Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import styles from './Dropdown.module.scss';

export function DropdownItem({icon, children, className, ...props}) {
  return (
    <Dropdown.Item active={false} {...props} className={classnames(styles.dropdownItem, className)}>
      {icon != null ? <FontAwesomeIcon icon={icon} fixedWidth className={styles.dropdownIcon} /> : null}
      <span className={styles.dropdownText}>{children}</span>
    </Dropdown.Item>
  );
}
