import React from 'react';
import {DropdownItem} from './Dropdown';
import {LinkContainer} from 'react-router-bootstrap';
import {WebRoutes, ExternalURLs} from '../Constants';

export function Projects() {
  return (
    <>
      <DropdownItem href={ExternalURLs.BETTERTTV}>BetterTTV</DropdownItem>
      <DropdownItem href={ExternalURLs.NIGHTBOT}>Nightbot</DropdownItem>
      <LinkContainer to={WebRoutes.KAPCHAT}>
        <DropdownItem>KapChat</DropdownItem>
      </LinkContainer>
      <LinkContainer to={WebRoutes.TWITCHCAST}>
        <DropdownItem>TwitchCast</DropdownItem>
      </LinkContainer>
      <DropdownItem href={ExternalURLs.PUSSH}>Pussh</DropdownItem>
    </>
  );
}
